import React, { useState } from 'react';
import { graphql } from "gatsby"
import { useIntl, injectIntl } from "gatsby-plugin-intl"

import Seo from "../components/seo-old"
import ProjetNavbar from '../components/Projet_Template/ProjetNavbar';
import ProjetGsap from '../components/Projet_Template/ProjetGsap';
import ProjetRichText from '../components/Projet_Template/ProjetRichText';

export const query = graphql`
  query ContentFulProjects($slug: String, $locale: String) {
    contentfulProjects(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      node_locale
      title
      category
      sector
      year
      client
      location
      client
      slogan
      partenaire
      mainPicture{
        title
        file { 
          url 
        }
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          formats: [WEBP]
          quality: 50
        )
      }
      images {
        title
        description
        file{
          url
          details {
            image {
              width
              height
            }
          }
        }
        gatsbyImageData(
          height: 2000
          placeholder: BLURRED
          formats: [WEBP]
          quality: 50
        )
      }
      titreProchainProjet
      slugProchainProjet
      richText1 {
        raw
      }
      richText2 {
        raw
      }
      richText3 {
        raw
      }
    }
}`

const Projet = ({ data, location }) => {

  const [showRichText, setShowRichText] = useState(false);
  const [showRichBg, setShowRichBg] = useState(false);

  const toggleText = () => {
    setShowRichText(!showRichText);
    setShowRichBg(!showRichBg);
  }


  const intl = useIntl()
  let options

  if (intl.formatMessage({ id: "lang" }) === "fr") {
    options = {
      renderNode: {
        "embedded-asset-block": node => {
          const alt = node.data.target.fields.title["fr"]
          const url = node.data.target.fields.file["fr"].url
          return <img alt={alt} src={url} />
        },
      },
    }
  }

  if (intl.formatMessage({ id: "lang" }) === "en") {
    options = {
      renderNode: {
        "embedded-asset-block": node => {
          const alt = node.data.target.fields.title["fr"]
          const url = node.data.target.fields.file["fr"].url
          return <img alt={alt} src={url} />
        },
      },
    }
  }

  if (data.contentfulProjects.category === null) {
    data.contentfulProjects.category = ["pas de catégorie"]
  }

  var toStop = false;
  toStop = () => { toStop = true }


  const isBrowser = typeof window !== "undefined"

  if (!data) return null
  return (
    <React.Fragment>

      <Seo
        lang={intl.formatMessage({ id: "lang" })}
        pathname={location.pathname}
        title={"Perroquet - " + data.contentfulProjects.title}
        description={data.contentfulProjects.client + " a choisi Perroquet pour un projet " + data.contentfulProjects.category[0] + " en " + data.contentfulProjects.year + " à " + data.contentfulProjects.location + "."}
        keywords={
          data.contentfulProjects.category + ", " +
          data.contentfulProjects.sector + ", " +
          data.contentfulProjects.client + ", " +
          data.contentfulProjects.location + ", " +
          "design graphique"}
        imageProjet={"https:" + data.contentfulProjects.mainPicture.file.url}

      />
      {isBrowser &&
        <ProjetNavbar data={data} toStop={toStop()} />
      }
      {isBrowser &&
        <ProjetGsap data={data} langue={intl.formatMessage({ id: "lang" })} toggleText={toggleText} />
      }
      <ProjetRichText data={data} toggleText={toggleText} showRichBg={showRichBg} showRichText={showRichText} />

    </React.Fragment>
  )
}
export default injectIntl(Projet)